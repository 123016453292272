<template>
  <div>
    <a-form
      @keydown.enter.prevent=""
      ref="formRef"
      :model="formState"
      :label-col="{ span: 0 }"
      :wrapper-col="wrapperCol"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
    >
      <div class="grid grid-cols-2 items-center px-20px pt-18px">
        <div class="text-ems-gray200 text-16px font-semibold uppercase">
          {{ t('object_information.list_relate') }}
        </div>
        <div class="flex justify-end items-center gap-16px">
          <div class="w-240px">
            <InputFilter
              v-model:value="formState.name"
              :show-count="false"
              :maxlength="100"
              :placeholder="t('common.search')"
              @input="debounceInput"
            >
              <template #prefix>
                <img
                  src="../../../static/img/icon/IconSearch.svg"
                  alt="IconSearch"
                />
              </template>
            </InputFilter>
          </div>
          <div class="w-240px">
            <SelectFilter
              ref="relateSug"
              :options="RELATED_SUGGESTIONS"
              v-model:value="formState.type"
              :placeholder="t('object_information.related_suggestions')"
              mode="multiple"
              :allow-clear="true"
            >
            </SelectFilter>
          </div>
        </div>
      </div>
    </a-form>
    <div
      class="h-1px w-[calc(100%-40px)] mx-auto bg-ems-gray700 mt-17px mb-26px"
    ></div>
    <Empty
      v-if="
        formMode === FORM_MODE.CREATE || !listRelate || listRelate.length === 0
      "
      :description="t('common.no_data_displayed')"
      class="mt-[60px]"
    />
    <div
      v-else
      class="grid grid-cols-3 gap-20px pl-20px pr-10px mr-5px max-h-500px overflow-y-auto"
    >
      <div
        v-for="item in listRelate"
        class="group bg-ems-tag1 hover:bg-ems-gray700 border-1 !hover:border-ems-gray500 !border-ems-tag1 box-border min-h-159px rounded-8px p-20px flex flex flex-col 2xl:flex-row items-center gap-20px relative"
        @mouseleave="isShowOptions = false"
      >
        <img
          :src="
            item.imagesAvatar
              ? item.imagesAvatar
              : require('@/static/img/no-img.png')
          "
          alt="ExampleImg1"
          width="120"
          height="120"
          class="w-[120px] h-[120px] flex-shrink-0 object-cover"
        />
        <div>
          <div
            class="mb-12px text-18px font-semibold text-ems-gray100 leading-6"
          >
            {{ item.name }}
          </div>
          <div class="flex justify-center 2xl:justify-start">
            <div
              :class="`${
                item.color === COLOR_TYPE.FAMILY
                  ? 'bg-ems-instagram'
                  : item.color === COLOR_TYPE.FATHER ||
                    item.color === COLOR_TYPE.MOTHER
                  ? 'bg-ems-facebook'
                  : 'bg-ems-main1'
              } text-16px text-ems-gray100 leading-25px px-10px py-3px rounded-42px`"
            >
              {{ item.relationship }}
            </div>
          </div>
        </div>
        <div
          class="absolute top-12px right-12px flex flex-col hidden group-hover:block"
        >
          <div class="text-right mb-10px" @click="isShowOptions = true">
            <img
              src="../../../static/img/icon/MoreIcon.svg"
              alt="MoreIcon"
              class="cursor-pointer inline-block"
            />
          </div>
          <div v-if="isShowOptions" class="shadow-custom rounded-8px">
            <div
              class="text-ems-gray100 flex items-center px-16px py-20px gap-14px cursor-pointer bg-ems-gray700 hover:bg-ems-gray600 rounded-t-8px"
            >
              <cds-feather-icons type="search" size="16" />
              {{ t('object_information.search') }}
            </div>
            <div class="h-1px w-full bg-ems-gray500"></div>
            <div
              @click="onViewDetail(item)"
              class="text-ems-gray100 flex items-center px-16px py-20px gap-14px cursor-pointer bg-ems-gray700 hover:bg-ems-gray600 rounded-b-8px"
            >
              <cds-feather-icons type="eye" size="16" />
              {{ t('object_information.view_detail') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, reactive, ref, watch } from 'vue';
import InputFilter from '@/components/input-filter/index.vue';
import SelectFilter from '@/components/select-filter/index.vue';
import { FORM_MODE, RELATED_SUGGESTIONS } from '@/util/common-constant';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash';
import Empty from '@/components/empty/index.vue';

const { t } = useI18n();
const { state, dispatch } = useStore();
const isShowOptions = ref(false);
const listRelate = computed(() =>
  convertObj(state.ioManagement.detailObjRelate)
);
const formMode = computed(() => state.ioManagement.formMode);

const route = useRoute();

const formState = reactive({
  name: null,
  type: null,
});

const debounceInput = debounce(function () {
  dispatch('ioManagement/getDetailObjRelate', {
    id: route.query.id,
    params: formState,
  });
}, 300);

watch(
  () => formState.type,
  () => {
    dispatch('ioManagement/getDetailObjRelate', {
      id: route.query.id,
      params: formState,
    });
  }
);

const onViewDetail = (item) => {
  window.open(
    `/io-management/object-management/edit?id=${item.id}&type=${item.type}&mode=${FORM_MODE.VIEW}`,
    '_blank'
  );
};

const convertObj = (obj) => {
  const lst = [];
  if (obj) {
    if (obj.family) {
      obj.family.forEach((e) =>
        lst.push({
          id: e.id,
          type: e.type,
          imagesAvatar: e.imagesAvatar,
          name: e.name,
          relationship: t('object_information.relatives'),
          color: COLOR_TYPE.FAMILY,
        })
      );
    }
    if (obj.father) {
      obj.father.forEach((e) =>
        lst.push({
          id: e.id,
          type: e.type,
          imagesAvatar: e.imagesAvatar,
          name: e.name,
          relationship: t('object_information.father'),
          color: COLOR_TYPE.FATHER,
        })
      );
    }
    if (obj.mother) {
      obj.mother.forEach((e) =>
        lst.push({
          id: e.id,
          type: e.type,
          imagesAvatar: e.imagesAvatar,
          name: e.name,
          relationship: t('object_information.mother'),
          color: COLOR_TYPE.MOTHER,
        })
      );
    }
    if (obj['owner']) {
      obj['owner'].forEach((e) =>
        lst.push({
          id: e.id,
          type: e.type,
          imagesAvatar: e.imagesAvatar,
          name: e.name,
          relationship: t('object_information.owner'),
          color: COLOR_TYPE.OWNER,
        })
      );
    }
    if (obj.sameAddress) {
      obj.sameAddress.forEach((e) =>
        lst.push({
          id: e.id,
          type: e.type,
          imagesAvatar: e.imagesAvatar,
          name: e.name,
          relationship: t('object_information.sameAddress'),
          color: COLOR_TYPE.SAME_ADDRESS,
        })
      );
    }
    if (obj.sameGroup) {
      obj.sameGroup.forEach((e) =>
        lst.push({
          id: e.id,
          type: e.type,
          imagesAvatar: e.imagesAvatar,
          name: e.name,
          relationship: t('object_information.sameGroup'),
          color: COLOR_TYPE.SAME_GROUP,
        })
      );
    }
    if (obj.sameOwner) {
      obj.sameOwner.forEach((e) =>
        lst.push({
          id: e.id,
          type: e.type,
          imagesAvatar: e.imagesAvatar,
          name: e.name,
          relationship: t('object_information.sameOwner'),
          color: COLOR_TYPE.SAME_OWNER,
        })
      );
    }
    if (obj.spouse) {
      obj.spouse.forEach((e) =>
        lst.push({
          id: e.id,
          type: e.type,
          imagesAvatar: e.imagesAvatar,
          name: e.name,
          relationship: t('object_information.spouse'),
          color: COLOR_TYPE.SPOUSE,
        })
      );
    }
  }
  return lst;
};

const COLOR_TYPE = {
  FATHER: 1,
  MOTHER: 2,
  FAMILY: 3,
  OWNER: 4,
  SAME_ADDRESS: 5,
  SAME_GROUP: 6,
  SAME_OWNER: 7,
  SPOUSE: 8,
};
</script>

<style scoped>
.shadow-custom {
  box-shadow: 0 4px 84px 0 #000000;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
}
</style>
